import MoblieHeader from '../../../control/MoblieHeader.vue';
import MoblieFooter from '../../../control/MoblieFooter.vue';
import Home from './Home.vue';
export default {
  data() {
    return {};
  },

  components: {
    MoblieHeader,
    MoblieFooter,
    Home
  },
  computed: {
    activeComponent() {
      return this.$store.state.component;
    },

    isnavopne() {
      return this.$store.state.isnavopne;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'ChatBase');
    this.$store.commit('changeUserType', 'user');
  },

  methods: {}
};