import axios from 'axios';
import { _ } from 'core-js'
const config = require('../../../config')
const state = () => ({
  axios: {},
  _t:{},
  shopping_cart_num: 0,
  user_info: null, // 用户登录信息，null表示未登录的
  isusernav: false, // 判断显示个人中心二级导航
  isnavopne: false, // 判断二级导航是否展开
  navText: {
    storeServer: config.storeServer,
    home: config.mobilePath() + '/index.html', // 首页: "Home"
    shopping: config.mobilePath() + '/shopping.html', // 商城: "Shopping"
    collocation: config.mobilePath() + '/collocation.html', // 搭配中心: "Collocation"
    download: config.storeServer() + config.mobilePath() + '/regular.html', // 下载中心: "Download"
    help: 'knowledge', // 服务中心: "Help"
    contact: config.mobilePath() + '/contact.html', // 联系我们: "Contact"
    regular: config.mobilePath() + '/regular.html', // 常规下载: "Regular"
    simAppPro: config.storeServer() + config.mobilePath() + '/simapppro.html', // SimApp Pro: "SimApp Pro"

    cart: config.storeServer() + config.mobilePath() + '/cart.html', // 购物车: "cart"
    login: config.storeServer() + config.mobilePath() + '/login.html', // 登陆: "Log in"
    register: config.storeServer() + config.mobilePath() + '/register.html', // 注册: "Register"
    my: config.storeServer() + config.mobilePath() + '/my.html', // 个人中心: "User center"
    my_order: config.storeServer() + config.mobilePath() + '/my-order.html', // 我的订单: "My order"
    logout: config.storeServer() + config.mobilePath() + '/logout.html', // 退出: "Log out"

    about: config.storeServer() + config.mobilePath() + '/contact.html', // 关于我们: "About"
    clause: config.storeServer() + config.mobilePath() + '/clause.html', // 服务条款: "Clause"
    problem: 'knowledge', // 常见问题: "Problem"
    sitemap: config.storeServer() + config.mobilePath() + '/sitemap.html', // 网站地图: "Site map"
    wINWING: '', // 翼胜科技: "WINWING"

    custservi: config.storeServer() + config.mobilePath() + '/custservi.html', // 在线客户: "custservi"
    goods_details: config.storeServer() + config.mobilePath() + '/goods-details.html', // 商城详情: "details"
    emaillverifi: config.storeServer() + config.mobilePath() + '/emaillverifi.html', // 邮箱验证: "emaillverifi"
    buy_info: config.storeServer() + config.mobilePath() + '/buy-info.html', // 确认订单信息: "address"
    my_addressbook: config.storeServer() + config.mobilePath() + '/my-addressbook.html', // 地址管理: "addressbook"
    my_information: config.storeServer() + config.mobilePath() + '/my-information.html', // 资料设置: "information"
    my_modifypassword: config.storeServer() + config.mobilePath() + '/my-modifypassword.html', // 修改密码: "modifypassword"
    forgotpassword: config.storeServer() + config.mobilePath() + '/forgotpassword.html', // 忘记密码: "forgotpassword"
    my_order_details: config.storeServer() + config.mobilePath() + '/my-order-details.html', // 订单详情: "order-details"
    buy_pay: config.storeServer() + config.mobilePath() + '/buy-pay.html', // 支付页面: "buy_pay"

    buy_pay_success: 'buy-pay-success.html', // 支付成功 || 预定成功: "buy_pay-success"

    flow_youtube: 'https://www.youtube.com/channel/UCrfUSM_r82yBrMreUus0Cdw',
    flow_blili: 'https://space.bilibili.com/59396916?spm_id_from=333.337.0.0',
    flow_facebook: 'https://www.facebook.com/winwing.cn',
    flow_EDbbs: 'https://forums.eagle.ru/forum/605-winwing/'
  }, // 导航内容
  navshopitem: [{
    img: '/moblie/images/nav-img-HOTAS.png',
    text: 'hotas',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=133'
  },
  {
    img: '/moblie/images/nav-img-Combo.png',
    text: 'combo',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=134'
  },
  {
    img: '/moblie/images/nav-img-ControlPanel.png',
    text: 'controlPanel',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=140'
  },
  {
    img: '/moblie/images/nav-img-Grip.png',
    text: 'gripAndHandle',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=135'
  },
  {
    img: '/moblie/images/nav-img-BASE.png',
    text: 'base',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=136'
  },
  {
    img: '/moblie/images/nav-img-Accessories.png',
    text: 'accessories',
    url: config.storeServer() + config.mobilePath() +'/shopping.html?id=139'
  }
  ]
})

const getters={
  getNavShopItem:(state)=>{
    const items=_.cloneDeep(state.navshopitem);
    for(let item of items){
      item.text=state._t(item.text)
    }
    return items;
  }
}

const mutations = {
  changeShoppingCartNumber (state, payload) {
    state.shopping_cart_num = payload
  },
  changeAxios (state, payload) {
    state.axios = payload
  },
  changeI18n (state, payload) {
    state._t = payload
  }
}

const actions = {
  async post ({ state }, url, param, config) {
    const baseConfig = {
      isMoblie: true,
      ...config
    }
    const axios = state.axios
    const res = await axios.post(url, param, baseConfig)
    return res
  },
  async getCartNumber ({ dispatch, commit }) {
    const area_mark = localStorage.getItem('area_mark')
    const path_url = localStorage.getItem('shopUrl')
    axios.post('/api/user/Base/getCartNumber', {
      area_mark: area_mark,
      path_url: path_url
    }).then( res => {
      commit('changeShoppingCartNumber', res.data.data)
    }).catch(err => {
      console.log('获取购物车数量错误')
    })
    
  },
  async subscribeToNews ({ dispatch }, vue, email) {
    const res = await dispatch('post', '/home/service/subscribeToNews', {
      email
    })
    if (res.status !== 200 || res.data.errno) {
      vue.$notify({
        title: vue._t('error'),
        type: 'error',
        message: vue._t('subscribedFailed')
      })
    }
    vue.$notify({
      title: vue._t('successful'),
      type: 'success',
      message: vue._t('subscribedSuccessfully')
    })
  },
  async logOut ({ state, dispatch }) {
    // 移除购物网站后台session
    // const res1 = await dispatch('post', '/center/public/logout_en')
    // if (res1.status !== 200 || res1.data.errno) {
    //   console.log('store注销失败')
    // }
    // 移除faq后台session
    const axios = state.axios
    var url = '/api/user/Base/logout'
    const res2 = await axios.post(url, {}, {
      isMoblie: true
    })
    if (res2.status !== 200 || res2.data.errno) {
      console.log('faq注销失败')
    }
    if (state.navText.logout !== '') {
      location.href = state.navText.logout + '?bkurl=' + location.href
    } else {
      location.reload()
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
