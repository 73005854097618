import bus from 'vue3-eventbus';
import Modal from '@/control/Modal.vue';
import Editor from '@/control/Editor.vue';
import QuestionTypeFilter from '@/control/Filter/QuestionTypeFilter.vue';
import MixedFilter from '@/control/Filter/MixedFilter.vue';
import FilterPanel from '@/control/FilterPanel.vue';
import FilterPanel2 from '@/control/FilterPanel2.vue';
import { mapGetters, mapState } from 'vuex';
import Catalogue from '../Sop/Control/Catalogue.vue';
import Assignment from '../Sop/Control/Assignment.vue';
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
export default {
  setup(props, context) {
    const isUserApply = ref(0);
    const tagTitle = ref(null);
    const timer = ref({});
    const isShow = ref(true);
    const refreshQuestionData = ref(false);
    const onLineUser = ref([]);
    const userId = ref('');
    const questionRecordList = ref([]);
    const isRefresh = ref(false);
    bus.on('init', messageData => {
      userId.value = messageData.userId;
    });
    bus.on('event-userNewMsgUnreadCount', messageData => {
      tagTitle.value = messageData.data;
      const title = 'wwt-faq';

      if (tagTitle.value) {
        clearInterval(timer.value);
        timer.value = setInterval(() => {
          isShow.value = !isShow.value;

          if (isShow.value) {
            document.title = title + `[${tagTitle.value}message]`;
          } else {
            document.title = title;
          }
        }, 1000);
      }

      if (messageData.questionRecordId) {
        refreshQuestionData.value = true;
      }
    });
    bus.on('event-userFinishApply', messageData => {
      if (messageData.questionRecordId == localStorage.getItem("questionRecordId")) {
        isUserApply.value = 3;
      }
    });
    bus.on('event-userCancelApply', messageData => {
      if (messageData.questionRecordId == localStorage.getItem("questionRecordId")) {
        isUserApply.value = 0;
      }
    });
    bus.on('event-userNewMsgAdminUnreadCount', messageData => {
      for (const data of questionRecordList.value) {
        for (const item of data.item) {
          if (Number(messageData.questionRecordId) == item.id) {
            item.admin_unread = messageData.data;
          }
        }
      }

      isRefresh.value = true;
    });
    bus.on('event-userOnlineData', messageData => {
      onLineUser.value = messageData.data;

      for (const recordList of questionRecordList.value) {
        recordList.isOnLine = false;
      }

      if (!_.isEmpty(onLineUser.value)) {
        for (const data of onLineUser.value) {
          for (const recordList of questionRecordList.value) {
            if (JSON.parse(recordList.user).name == data.userName) {
              recordList.isOnLine = true;
              break;
            }
          }
        }
      }
    });
    return {
      isUserApply,
      refreshQuestionData,
      onLineUser,
      userId,
      questionRecordList,
      isRefresh
    };
  },

  components: {
    Modal,
    Editor,
    QuestionTypeFilter,
    MixedFilter,
    Catalogue,
    FilterPanel,
    FilterPanel2,
    Assignment
  },

  data() {
    return {
      questionTypeSelected: [],
      questionTypeOptions: [],
      // 问题类型接口数据
      part: false,
      wsQuestionId: null,
      _: _,
      resolvedFilter: false,
      noReplyFilter: false,
      sendContent: '',
      sendAIContent: '',
      viewQuestionStepModalShow: false,
      commentModalShow: false,
      viewOrderModalShow: false,
      timer: {},
      turn: false,
      orderStatus: 0,
      questionRecordCurrent: {},
      imgArray: [],
      img: '',
      imgIndex: 0,
      imgViewerShow: false,
      commentTemp: {},
      scrollAjustById: {},
      treeListData: [],
      customerOrderListData: [],
      // 客户订单列表
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      isShowTable: false,
      showAI: false,
      drawer: false,
      isHidden: false,
      drawerTimer: null,
      loading: false,
      directoryId: null,
      questionDataId: null,
      // questionListById: 0,
      AiData: null,
      message: '',
      currentAccount: '',
      catalogId: null,
      questionData: [],
      questionDataIds: [0],
      loadingAIChat: false,
      refresh: false,
      loadingTextArray: [],
      updateTimer: {},
      appriseMsg: '',
      //申请评价成功后，展示内容
      isApply: 0,
      checkedSolveOptions: [],
      solveOptions: [{
        id: 1,
        label: 'resolved'
      }, {
        id: 0,
        label: 'no-reply'
      }, {
        id: 2,
        label: 'replied'
      }],
      // questionRecordList: [], // 问题列
      itemsPerPage: 100,
      // 每页显示条数
      currentPage: 1,
      // 页码
      isLoading: false,
      totelItem: 0,
      selectItem: null,
      drawerAssignment: false,
      assignmentQuestionId: null,
      //分派问题id
      customerServiceId: null,
      // 客服人员id
      IssueNotes: null,
      //问题备注时的id
      questionCustomerArray: [{
        id: 0,
        name: 'Unassigned problem'
      }],
      // 客服人员
      questionCustomerIdArray: [],
      startTime: undefined,
      endTime: undefined,
      clientName: undefined,
      isDragging: false,
      panelWidth: 200,
      // 初始宽度
      copyId: null,
      chatRecordList: [],
      // 聊天记录
      recordData: null,
      questionRecordByIdData: null,
      recallTimer: {},
      newChatRecordList: [],
      menuTop: 0,
      menuLeft: 0,
      logisticsInformation: [],
      logisticsInformationData: {},
      dialogTableVisible: false,
      questionId: 0,
      emergencyDegreeSelect: [],
      emergencyDegreeOption: []
    };
  },

  computed: { ...mapState({
      user: 'user',
      order: 'order',
      isScorePanelShow: "isScorePanelShow",
      questionRecord: 'questionRecord',
      questionRecordId: 'questionRecordId',
      // 当前聊天窗的问题id
      questionRecordUser: 'questionRecordUser'
    }),
    ...mapGetters({
      questionById: 'getQuestionById',
      questionTypeById: 'getQuestionTypeById',
      questionRecordById: 'getQuestionRecordById',
      getPersonName: 'getPersonName',
      getPersonBackgroundColor: 'getPersonBackgroundColor',
      getFilterQuestionRecordByUser: 'getFilterQuestionRecordByUser'
    }),

    orderByBuy() {
      var order = [];

      for (const _order of this.order) {
        if (_order.is_del == 0 && _order.status == 3 && _order.pay_status == 3 && _order.delivery_status == 3) {
          order.push(_order);
        }
      }

      return order;
    },

    itemByUser() {
      var item = [];

      for (const _order of this.orderByBuy) {
        for (const _goods of _order.order_goods) {
          _goods.prom_goods = JSON.parse(_goods.prom_goods);

          if (_goods.prom_goods.goods_no) {
            item = item.concat(_goods.prom_goods.goods_no.split('&')); // &
          }
        }
      }

      item = _.uniq(item);
      return item;
    },

    questionRecordSelected() {
      const questionRecordSelected = this.questionRecordById[this.questionRecordId] || {};
      return questionRecordSelected;
    },

    title() {
      if (this.questionRecordId == 0) {
        return '';
      }

      var record = this.questionRecordById[this.questionRecordId];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    scrollPosition() {
      return this.$store.state.scrollPosition;
    } // recordData() {
    //   const storeData = localStorage.getItem('recordData')
    //   if (storeData) {
    //     const recordData = JSON.parse(storeData);
    //   }
    //   return recordData
    // },


  },
  watch: {
    emergencyDegreeSelect: {
      handler(nv, ov) {
        this.update();
      },

      immediate: true
    },
    isRefresh: {
      handler(nv, ov) {
        if (nv == true) {
          this.getUserHistoriesChatRecordListByQuestionRecordId();
          const record = JSON.parse(localStorage.getItem('recordData'));
          this.getUserChatRecordListByQuestionRecordId(record);
          this.isRefresh = false;
        }
      }

    },
    chatRecordList: {
      handler(nv, ov) {
        if (this.isShowTable === false) {
          const store = localStorage.getItem('recordData');

          if (store) {
            this.questionRecordByIdData = JSON.parse(store).is_solved;
          }

          this.$nextTick(() => {
            this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
          });
        }
      },

      immediate: true
    },
    refreshQuestionData: {
      handler(nv, ov) {
        if (nv == true) {
          const store = localStorage.getItem('recordData');
          const recordData = JSON.parse(store);
          this.getUserChatRecordListByQuestionRecordId(recordData);
          this.refreshQuestionData = false;
        }
      }

    },
    checkedSolveOptions: {
      handler(nv, ov) {
        this.questionRecordList = [];
        this.currentPage = 1;
        this.update();
      },

      immediate: true
    },
    questionTypeSelected: {
      handler(nv, ov) {
        this.questionRecordList = [];
        this.currentPage = 1;
        this.update();
      },

      immediate: true
    },
    questionCustomerIdArray: {
      handler(nv, ov) {
        this.questionRecordList = [];
        this.currentPage = 1;
        this.update();
      },

      immediate: true
    },
    isUserApply: {
      handler(nv, ov) {
        if (nv == 1) {
          this.$store.commit('changeIsScorePanelShow', true);
        }
      },

      immediate: true
    },
    chatReocrd: {
      handler(nv, ov) {
        const chatLength = this.chatReocrd && Object.keys(this.chatReocrd).length || 0;

        if (chatLength) {
          this.scrollAjust(this.questionRecordId, chatLength);
        }
      },

      flush: 'post'
    },
    getFilterQuestionRecordByUser: {
      handler(nv, ov) {
        if (this.questionRecordId == 0 && !_.isEmpty(nv)) {
          this.$store.commit('changeQuestionRecordUser', nv.user);
          this.$store.commit('changeQuestionRecordId', nv.question_record_id);
        }
      }

    },
    questionRecordSelected: {
      handler(nv, ov) {
        if (nv == ov) {
          return;
        }

        this.questionListById = this.questionRecordId; // 问题id

        if (this.currentAccount == this.questionRecordSelected?.user?.account) {
          return;
        }

        this.currentAccount = this.questionRecordSelected?.user?.account;
        this.getUserHisAiList();
        this.getOrderByUser();
      }

    },
    questionRecordId: {
      handler(nv, ov) {
        if (nv == ov) {
          return;
        }

        this.getFAQAppraiseStatus();
      },

      immediate: true
    },
    AiData: {
      handler(nv, ov) {
        if (_.isEmpty(this.AiData)) {
          return;
        }

        this.scrollToBottom();
      },

      immediate: true
    }
  },

  mounted() {
    const storeData = localStorage.getItem('recordData');

    if (storeData) {
      const recordData = JSON.parse(storeData);
      this.clickQuestionRecord(recordData);
    }

    this.getDictDataList();
    this.getQuestionTypeList();
    this.getPersonDataList();
    this.$refs.recordListRef.scrollTop = this.scrollPosition;
    document.addEventListener('click', this.hideRecallMenuOnClickOutside);
    this.getUserHistoriesChatRecordListByQuestionRecordId();
    this.update();
  },

  unmounted() {
    document.removeEventListener('click', this.hideRecallMenuOnClickOutside);
  },

  methods: {
    // 下载发票
    async clickDownload(row) {
      if (row.sales_id == null || row.sales_id == '') {
        ElNotification({
          title: '错误',
          message: '销售ID为空',
          type: 'error',
          duration: 1500
        });
        return;
      }

      const res = await this.$axios.post('/api/admin/Base/orderdownloadInvoice', {
        salesIdArray: [row.sales_id]
      });

      if (res.data.errno != 0) {
        ElNotification({
          title: '请求失败',
          message: res.data.errmsg,
          type: 'error'
        });
        return;
      }

      const _data = Buffer.from(res.data.data, 'binary');

      const _blob = new Blob([_data], {
        type: 'application/zip'
      });

      const _url = window.URL.createObjectURL(_blob);

      const a = document.createElement('a');
      a.download = '发票.zip';
      a.href = _url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getDictDataList() {
      const res = await this.$axios.post('/java/front/getDictDataList', {
        dictType: 'urgency_level'
      });

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.emergencyDegreeOption = res.data.data.map(data => {
        return { ...data,
          id: data.dictValue,
          val: data.dictLabel + '(' + data.dictValue + ')'
        };
      });
    },

    orderType(orderType) {
      if (orderType == 1) {
        return '调货';
      } else if (orderType == 2) {
        return '现货';
      } else if (orderType == 3) {
        return '预购';
      } else {
        return '未知';
      }
    },

    // 显示物流信息
    async clickShowTable(item, row) {
      const a = document.createElement('a');
      a.target = '_blank';
      let url = '';

      if (row.area === 'global') {
        url = `https://www.fedex.com/fedextrack/?trknbr=${item}`;
      } else if (row.area === 'china') {
        url = `https://www.sf-express.com/chn/sc/waybill/waybill-detail/${item}`;
      } else {
        url = `https://t.17track.net/en#nums=${item}`;
      }

      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // const res = await this.$axios.post('/api/admin/Base/getOrderTrackingStatus', {
      //   waybillNum: item
      // })
      // if (res.data.errno != 0) {
      //   ElNotification({
      //     title: '失败',
      //     message: res.data.errmsg,
      //     type: 'error',
      //     duration: 1500
      //   })
      //   return
      // }
      // this.logisticsInformationData = res.data.data
      // for (const data of res.data.data.trjectoryInfoArray) {
      //   this.logisticsInformation = data
      // }
      // this.dialogTableVisible = true
    },

    // 全局监听点击事件
    hideRecallMenuOnClickOutside(event) {
      for (const chats of this.chatRecordList) {
        chats.showRecall = false;
      }
    },

    chatRecall(e, chat) {
      e.preventDefault();

      if (chat.person_id == 0 || chat.id !== this.newChatRecordList.last_data.id) {
        return;
      }

      for (const chats of this.chatRecordList) {
        chats.showRecall = false;
      }

      this.menuTop = e.clientY;
      this.menuLeft = e.clientX;
      chat.showRecall = true;
    },

    clickChat(chat) {
      // 判断聊天内容是否是图片标签
      const imgRegex = /<img\b[^>]*>/i;
      const containsImgTag = imgRegex.test(chat.content);

      if (!containsImgTag) {
        return;
      } // // 记录所有图片信息,并将图片替换为element框架<el-image>


      const imgArray = []; // var index = 0
      // 搜索src

      var reg = /<img[^>]*\bsrc=['"]([^'"]*)['"]/ig;
      var match;

      while ((match = reg.exec(chat.content)) !== null) {
        // match[1]是捕获组匹配的内容，即src属性的值
        const src = match[1];
        imgArray.push(src); // chat.content = chat.content.replace(`<img src="${src}"`, `<img class='chatImg' style='width:20rem;' data-index=${index} src='${src}'`);
        // index++;
      }

      this.imgArray = imgArray;
      this.imgIndex = 0;
      this.imgViewerShow = true; // this.$nextTick(() => {
      //   this.addChatImgClick()
      // })
    },

    update() {
      this.updateTimer && clearTimeout(this.updateTimer);
      this.refresh = true;
      this.isLoading = true;
      this.updateTimer = setTimeout(async () => {
        this.queryQuestionRecordList();
        this.getAllDirectoryList();
        this.refresh = false;
        this.isLoading = false;
      }, 1000);
    },

    startDrag(event) {
      event.preventDefault();
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },

    handleDrag(event) {
      const container = this.$refs.dragContainer;
      const mouseX = event.pageX;
      const newWidth = mouseX - container.getBoundingClientRect().left;
      container.style.width = `${newWidth}px`;
    },

    stopDrag() {
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },

    copyContent(record) {
      this.copyId = record.id;
      const textToCopy = record.type_title;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      ElNotification({
        title: '成功',
        message: '复制成功',
        type: 'success',
        duration: 1500
      });
    },

    // 点击问题
    async clickQuestionRecord(record) {
      this.refresh = true;

      if (record) {
        this.recordData = record;
      }

      localStorage.setItem('recordData', JSON.stringify(this.recordData));

      if (record) {
        this.getUserChatRecordListByQuestionRecordId(record);
      }

      this.$refs.recordListRef.scrollTop = 0;
      this.selectItem = record.id;
      this.customerOrderListData = [];
      this.getOrderByUser(record); // this.queryTrackingStatus()

      const res = await this.$axios.get('/java/front/getUserAllyQuestionRecordListByQuestionRecordId?questionRecordId=' + record.id);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      if (res.data.data == null || _.isEmpty(res.data.data)) {
        ElNotification({
          title: '提示',
          message: '暂无数据',
          type: 'error',
          duration: 1500
        });
      }

      const clickedItem = res.data.data;

      for (let i = 0; i < this.questionRecordList.length; i++) {
        let foundMatch = false;

        for (let j = 0; j < this.questionRecordList[i].item.length; j++) {
          if (this.questionRecordList[i].item[j].id === record.id) {
            this.questionRecordList.splice(i, 1); // 先删除当前项

            this.questionRecordList.unshift(clickedItem); // 再将其插入到数组的开头

            foundMatch = true;
            break;
          }
        }

        if (!foundMatch) {
          // 如果当前项内没有与 record.id 匹配的 item.id，则将其折叠只显示第一个 item
          this.questionRecordList[i].item = [this.questionRecordList[i].item[0]];
        }
      }

      this.$store.commit('changeQuestionRecordUser', JSON.stringify(record.user));
      this.$store.commit('changeQuestionRecordId', record.id);
      const currentDate = new Date().getTime(); // 记录到数据库

      var addArray = [];
      var changeArray = [];

      if (this.getFilterQuestionRecordByUser && this.getFilterQuestionRecordByUser.id) {
        changeArray.push({
          id: this.getFilterQuestionRecordByUser.id,
          person_id: this.user.id,
          user: JSON.stringify(record.user),
          question_record_id: record.id,
          change_time: currentDate
        });
      } else {
        addArray.push({
          person_id: this.user.id,
          user: JSON.stringify(record.user),
          question_record_id: record.id,
          change_time: currentDate
        });
      }

      this.refresh = false;
    },

    // 根据问题记录编号查询聊天记录
    async getUserChatRecordListByQuestionRecordId(record) {
      // this.refresh = true
      const res = await this.$axios.get(`/java/front/getUserChatRecordListByQuestionRecordId?questionRecordId=${record.id}`);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          duration: 1500,
          type: 'error'
        });
        return;
      }

      this.chatRecordList = res.data.data;
      const data = this.chatRecordList.map(data => {
        return { ...data,
          time: this.$moment.utc(data.time).local().format('YYYY-MM-DD HH:mm:ss')
        };
      });
      this.chatRecordList = data; // setTimeout(() => {
      //   this.refresh = false
      // }, 500)
    },

    // 提交搜索客户名称内容
    submitclientName() {
      this.catalogId = null;
      this.questionRecordList = [];
      this.update();
    },

    updateTreeData() {
      this.updateTimer && clearTimeout(this.updateTimer);
      this.refresh = true;
      this.updateTimer = setTimeout(async () => {
        this.refresh = false;
      }, 1000);
    },

    // 阻止点击textarea框冒泡到问题列表
    stopClickPropagation(e, record) {
      this.IssueNotes = record.id;
      e.stopPropagation();
    },

    // 提交备注
    async submitContent(record) {
      // this.$store.commit('setScrollPosition', this.$refs.recordListRef.scrollTop)
      // this.questionRecordList = []
      // this.currentPage = 1
      const res = await this.$axios.post('/java/front/adminRemarkQuestionRecord', {
        questionRecordId: this.IssueNotes,
        content: record.comment
      });

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          duration: 1500,
          type: 'error'
        });
        return;
      }
    },

    // 清空聊天记录按钮
    clearAIChatHistory() {
      ElMessageBox.confirm('确认清空聊天记录?', '提示', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.get('/java/front/delUserHistoriesAiChatList?questionId=' + this.questionRecordId);

        if (res.status == 200) {
          ElNotification({
            title: '成功',
            message: '聊天记录已清空',
            duration: 1500,
            type: 'success'
          });
        }

        this.getUserHisAiList();
      }).catch(() => {});
    },

    filterRecord(_recordArray) {
      for (const record of _recordArray) {
        if (this.questionDataIds.indexOf(record.directory_id) != -1) {
          return true;
        }
      }

      return false;
    },

    // 让滚动条保持在底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollRef.scrollTop = this.$refs.scrollRef.scrollHeight;
      });
    },

    // 获取客户订单列表
    async getOrderByUser(record) {
      if (record === undefined) {
        return;
      }

      try {
        const account = JSON.parse(record.user).account;
        const res = await this.$axios.post('/api/admin/Base/getOrderByUser', {
          account: account
        });

        if (res.data.errno != 0) {
          ElNotification({
            title: '失败',
            message: res.data.errmsg,
            duration: 1500,
            type: 'error'
          });
          return;
        }

        if (res.data.data === undefined) {
          return;
        }

        const formattedData = res.data.data.map(data => {
          return { ...data,
            order_create_time: this.$moment(data.order_create_time).format("YYYY-MM-DD"),
            goods_unit_price: data.goods_unit_price + data.currency_symbol,
            totalDiscountAmount: data.totalDiscountAmount + data.currency_symbol
          };
        });
        this.customerOrderListData = formattedData;
      } catch (err) {
        ElNotification({
          title: '失败',
          message: err,
          duration: 1500,
          type: 'error'
        });
      }
    },

    // async queryTrackingStatus () {
    //   const res = await this.$axios.post('sales/queryTrackingStatus', {})
    // },
    // 发送信息给AI
    async clickSendContentBySOPAI() {
      this.loadingAIChat = true;
      this.message = this.sendAIContent;
      this.sendAIContent = '';

      if (_.trim(this.message) === '') {
        this.message = '';
        this.inputWarning();
        return;
      } // ai聊天记录接口


      const res = await this.$axios.post('/java/front/sopAi', {
        type: 0,
        questionId: this.questionRecordId,
        content: this.message
      });

      if (res.data.code != 200) {
        return;
      }

      if (res.data.code == 200) {
        this.loadingAIChat = false;
      }

      this.AiData = res.data.data;
      this.scrollToBottom();
    },

    // 获取AI聊天历史记录
    async getUserHisAiList() {
      const res = await this.$axios.get('/java/front/getUserHisAiList?questionId=' + this.questionRecordId);

      if (res.data.code != 200) {
        return;
      }

      this.AiData = res.data.data;
    },

    // 警告弹窗
    inputWarning() {
      ElNotification({
        title: '警告',
        message: '输入内容不能为空',
        type: 'error',
        duration: 1500
      });
    },

    // 点击树形目录列表
    async clickQuestionCatalog(data, node, e) {
      if (this.clientName == undefined) {
        this.clientName = '';
      } // this.questionRecordList = []


      this.catalogId = data.id; // 根据目录编号获取所有问题列表

      const questionDataIds = [];
      const res = await this.$axios.post('/java/front/queryQuestionRecordList', {
        directoryId: this.catalogId,
        clientName: this.clientName,
        state: this.checkedSolveOptions,
        page: this.currentPage,
        size: this.itemsPerPage,
        urgencyLevel: this.emergencyDegreeSelect
      });

      if (res.data.errno != 0) {
        if ((res.data.data == null || _.isEmpty(res.data.data)) && res.data.errno == 100) {
          this.questionRecordList = [];
        } else {
          ElNotification({
            title: '错误',
            message: res.data.errmsg,
            type: 'error',
            duration: 1500
          });
        }
      } else {
        this.questionRecordList = res.data.data;

        for (const data of res.data.data) {
          questionDataIds.push(data.id);
        }
      }

      this.questionDataIds = questionDataIds;
      this.updateTreeData();
    },

    // 获取问题是否申请评价
    async getFAQAppraiseStatus() {
      if (this.selectItem == null) {
        return;
      }

      localStorage.setItem("questionRecordId", this.selectItem);
      const res = await this.$axios.get('/java/front/getFAQAppraiseStatus?questionRescordId=' + this.selectItem);

      if (res.data.code != 200) {
        this.$notify({
          title: '失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.isUserApply = res.data.data;
    },

    // 申请评价弹窗
    openPoppup() {
      this.$confirm('确认需要对此聊天进行评价申请, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.get('/java/front/sendFAQAppraise?questionRescordId=' + this.selectItem);

        if (res.data.code != 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'warning',
            duration: 1500
          });
          return;
        }

        this.appriseMsg = "申请成功";
        ElNotification({
          title: '成功',
          message: '申请成功',
          type: 'success',
          duration: 3000
        });
        this.isUserApply = 1;
        this.update(); // this.getFAQAppraiseStatus()
      }).catch(() => {});
    },

    // 获取问题类型集合
    async getQuestionTypeList() {
      const res = await this.$axios.get('/java/front/getQuestionTypeList');

      if (res.data.code != 200) {
        this.$notify({
          title: '请求失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.questionTypeOptions = res.data.data;
    },

    // 获取客服人员 
    async getPersonDataList() {
      const res = await this.$axios.get('/java/front/getPersonDataList');

      if (res.data.code != 200) {
        this.$notify({
          title: '请求失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      for (const data of res.data.data) {
        this.questionCustomerArray.push(data);
      }
    },

    // 获取所有目录树形结构
    async getAllDirectoryList() {
      if (this.clientName == undefined) {
        this.clientName = '';
      }

      const res = await this.$axios.get(`/java/admin/getAllDirectoryList?state=[${this.checkedSolveOptions}]&questionType=[${this.questionTypeSelected}]&personIds=[${this.questionCustomerIdArray}]&clientName=${this.clientName}`);

      if (res.data.code != 200) {
        this.$notify({
          title: '失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.treeListData = res.data.data.children;
    },

    // 获取问题列表
    async queryQuestionRecordList() {
      if (this.clientName == undefined) {
        this.clientName = '';
      }

      let data = {
        page: this.currentPage,
        size: this.itemsPerPage
      };

      if (this.selectItem != null || this.selectItem != undefined) {
        data.questionRecordId = this.selectItem;
      }

      if (this.clientName != '') {
        data.clientName = this.clientName;
      }

      if (this.checkedSolveOptions.length > 0) {
        data.state = this.checkedSolveOptions;
      }

      if (this.questionCustomerIdArray.length > 0) {
        data.personIds = this.questionCustomerIdArray;
      }

      if (this.questionTypeSelected.length > 0) {
        data.questionType = this.questionTypeSelected;
      }

      if (this.catalogId != null) {
        data.directoryId = this.catalogId;
      }

      data.urgencyLevel = this.emergencyDegreeSelect;
      const res = await this.$axios.post('/java/front/queryQuestionRecordList', data);

      if (res.data.errno != 0) {
        ElNotification({
          title: '提示',
          message: res.data.errmsg,
          type: 'warning',
          duration: 1500
        });
      }

      this.totelItem = res.data.total;

      if (res.data.data == null || _.isEmpty(res.data.data)) {
        this.$notify({
          title: '提示',
          message: res.data.msg,
          type: 'warning'
        });
        this.questionRecordList = [];
      } else {
        // 在问题列表滚动触底时进入，将新获取的数据添加在原有的问题列表中（此操作避免问题列表滚动触底后一直加载，知道将所有问题加载完的情况）
        if (this.isLoading) {
          // 问题列表滚动触底时isLoading为true
          this.questionRecordList = _.concat(this.questionRecordList, res.data.data);
        } else {
          this.questionRecordList = res.data.data;
        }
      }
    },

    // 滚动条触底事件
    scrollHandler() {
      if (this.isLoading) return;

      if (this.totelItem <= 0) {
        this.currentPage = 1;
        this.isLoading = false;
      } else if (this.currentPage * this.itemsPerPage < this.totelItem) {
        this.isLoading = true;
        setTimeout(() => {
          this.queryQuestionRecordList();
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }, 500);
        this.currentPage++;
      } else {
        this.isLoading = false;
      }
    },

    clickHidden() {
      this.isHidden = !this.isHidden;
    },

    // 点击客户订单按钮
    clickClientOrderList() {
      this.updateTreeData();
      this.isShowTable = true;
      this.getOrderByUser();
    },

    clickShowAI() {
      this.showAI = !this.showAI;
    },

    clickSopAdmin() {
      this.$router.push({
        path: '/admin/Sop/SopAdministration'
      });
    },

    clickSopForeground() {
      this.$router.push({
        path: '/admin/Sop/SopForeground'
      });
    },

    scrollAjust(questionRecordId, chatLength) {
      if (this.scrollAjustById[0] !== questionRecordId || this.scrollAjustById[questionRecordId] !== chatLength) {
        if (this.$refs.chat) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        }
      }

      this.scrollAjustById[0] = questionRecordId;
      this.scrollAjustById[questionRecordId] = chatLength;
    },

    getNav(id) {
      var record = this.questionRecordById[id];
      var nav = [];
      var index = 0;

      for (const _nav of this.questionTypeById[record.question_type_id].nav || []) {
        if (index > 0) {
          nav.push(_nav.title);
        }

        index++;
      }

      return nav;
    },

    getTitle(id) {
      var record = this.questionRecordById[id];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    getItemName(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];
        nameArray.push(_item.name);
      }

      return nameArray.join();
    },

    getItemNameAndDescription(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];

        if (_item.description) {
          nameArray.push(_item.name + '(' + _item.description + ')');
        } else {
          nameArray.push(_item.name);
        }
      }

      return nameArray.join();
    },

    clickReturnKnowledge() {
      this.$router.push({
        path: '/admin/knowledge'
      });
    },

    removeUnvalidContent(content) {
      const unvaild = '<p><br></p>';

      if (content.endsWith(unvaild)) {
        content = content.slice(0, -unvaild.length);
        content = this.removeUnvalidContent(content);
      }

      return content;
    },

    //根据问题记录编号查询历史聊天记录
    async getUserHistoriesChatRecordListByQuestionRecordId() {
      const res = await this.$axios.get('/java/front/getUserHistoriesChatRecordListByQuestionRecordId?questionRecordId=' + this.selectItem);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'warning',
          duration: 1500
        });
        return;
      }

      this.newChatRecordList = res.data.data;
    },

    // 发送消息
    clickSendContent(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }

      var sendContent = this.sendContent;
      sendContent = this.removeUnvalidContent(sendContent);

      if (sendContent.trim() == '') {
        return;
      }

      this.$axios.post('/api/admin/ChatBase/sendContent', {
        question_record_id: this.selectItem,
        content: sendContent
      }).then(res => {
        // this.clickQuestionRecord(this.recordData)
        if (res.data.errno) {} else {
          this.sendContent = '';
          this.questionRecordList[0].item[0].admin_unread = 0;
        }

        this.$nextTick(() => {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        });
        this.getUserHistoriesChatRecordListByQuestionRecordId();
        const record = JSON.parse(localStorage.getItem('recordData'));
        this.getUserChatRecordListByQuestionRecordId(record);
      }).catch(err => {
        console.log(err);
      });
    },

    clickViewQuestionStep() {
      this.isShowTable = false;
      this.$nextTick(() => {
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionRecord', {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
      this.clickQuestionRecord(this.recordData);
    },

    clickSolved(questionRecord) {
      questionRecord.is_solved = questionRecord.is_solved ? 0 : 1;
      this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
        is_solved: questionRecord.is_solved
      });
    },

    getLocalTime(time) {
      var localTime = this.$moment.utc(time).local().format('YYYY-MM-DD HH:mm:ss');
      return localTime;
    },

    clickComment() {
      this.commentModalShow = true;
    },

    clickChangeQuestionRecordComment(questionRecord) {
      this.submitContent(questionRecord);
      this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
        comment: this.commentTemp[questionRecord.id]
      });
    },

    getUnreadQuantity(questionRecordArray) {
      var quantity = 0;

      for (const _record of questionRecordArray) {
        if (_record.admin_unread) {
          quantity++;
        }
      }

      return quantity;
    },

    getUnsolvedQuantity(questionRecordArray) {
      var quantity = 0;

      for (const _record of questionRecordArray) {
        if (!_record.is_solved) {
          quantity++;
        }
      }

      return quantity;
    },

    getColor(user, questionRecordArray) {
      var color = '#999';
      var unsolvedQuantity = this.getUnsolvedQuantity(questionRecordArray);

      if (user == this.questionRecordUser) {
        color = '#000';
      } else {
        if (unsolvedQuantity) {
          color = '#555';
        }
      }

      return color;
    },

    clickViewOrder(user) {
      this.viewOrderModalShow = true;
    },

    getOrderStatus(order) {
      var status = '';

      if (order.status == 2) {
        status = '等待审核';
      } else if (order.status == 3) {
        status = '已审核';
      } else if (order.status == 4) {
        status = '已完成';
      } else if (order.status == 5 && order.refund_status == 1) {
        status = '退款完成';
      } else if (order.status == 5 && order.refund_status != 1) {
        status = '申请退款中';
      } else if (order.status == 6) {
        status = '已作废';
      }

      return status;
    },

    getPayStatus(order) {
      var status = '';

      if (order.pay_status == 0) {
        status = '未支付';
      } else if (order.pay_status == 1 && order.refund_status != 1) {
        status = '已支付';
      } else if (order.pay_status == 1 && order.refund_status == 1) {
        status = '已退款';
      } else if (order.pay_status == 2) {
        status = '捕获中';
      } else if (order.pay_status == 3) {
        status = '授权中';
      }

      return status;
    },

    getShipStatus(order) {
      var status = '';

      if (order.delivery_status == 0) {
        status = '未发货';
      } else {
        status = '已发货';
      }

      return status;
    },

    addChatImgClick() {
      const it = this;
      var chatImgArray = document.getElementsByClassName('chatImg');

      for (var img of chatImgArray) {
        img.onclick = e => {
          var index = e.target.dataset.index;
          it.imgIndex = Number(index);
          it.imgViewerShow = true;
        };
      }
    },

    async clickRecall(chat, index) {
      const res = await this.$axios.get('/java/front/withdrawalChatRecord?chatRecordId=' + chat.id);

      if (res.data.code != 200) {
        return;
      }

      this.clickQuestionRecord(this.recordData);
      this.sendContent = chat.content;
      this.getUserHistoriesChatRecordListByQuestionRecordId();
    },

    // 点击分类按钮
    clickSortQuestion(data) {
      if (data.is_solved == 1) {
        ElNotification({
          title: '完成提示',
          message: '该问题已解决',
          type: 'success',
          duration: 1500
        });
        return;
      }

      this.questionDataId = data.id;
      this.drawer = true;
    },

    // 关闭分类弹窗
    handleClose(done) {
      this.$refs.drawerRef.close();
    },

    closeAssignment(done) {
      this.$refs.drawerAssignmentRef.close();
    },

    // 确认提交分类
    clickSubmit(done) {
      if (this.questionDataId == null || this.questionDataId == undefined) {
        ElNotification({
          title: '错误',
          message: '请选择目录列表',
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.$confirm('确定要将此问题分类到此目录下吗？').then(async () => {
        // 问题分类到目录
        const res = await this.$axios.get(`/java/front/setFAQQuestionToOneDirectory?questionRescordId=${this.questionDataId}&directoryId=${this.directoryId}&questionId=${this.questionId ? this.questionId : 0}`);

        if (res.data.code !== 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 1500
          });
          return;
        }

        ElNotification({
          title: '成功',
          message: res.data.msg,
          type: 'success',
          duration: 1500
        });
        this.getAllDirectoryList();
        this.updateTreeData();
        setTimeout(() => {
          this.$refs.drawerRef.close();
        }, 2000);
      });
    },

    // 点击分派按钮
    clickQuestionToCustomer(data) {
      if (data.is_solved == 1) {
        ElNotification({
          title: '完成提示',
          message: '该问题已解决',
          type: 'success',
          duration: 1500
        });
        return;
      }

      this.assignmentQuestionId = data.id;
      this.drawerAssignment = true;
    },

    changeCustomerService(value) {
      this.customerServiceId = value.id;
    },

    // 提交分派人员
    async clickSubmitAssignment(done) {
      if (this.customerServiceId == null) {
        ElNotification({
          title: '提示',
          message: '请选择需要分派的客服人员',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      ElMessageBox.confirm('确认将此问题分派给当前客服人员?', '提示', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.get(`/java/front/setFAQQuestionToCustomer?questionRescordId=${this.assignmentQuestionId}&customerId=${this.customerServiceId}`);

        if (res.data.code != 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 1500
          });
          return;
        }

        ElNotification({
          title: '成功',
          message: '分派成功',
          type: 'success',
          duration: 1500
        });
        this.$refs.drawerAssignmentRef.close();
        this.update();
      }).catch(() => {});
    },

    async refreshData() {
      // 刷新数据
      const res = await this.$axios.post('/api/admin/Base/updateAllRecord', {});

      if (!res) {
        return;
      }
    },

    cancelForm() {
      this.$refs.drawerRef.close();
    },

    cancelAssignment() {
      this.$refs.drawerAssignmentRef.close();
    },

    getDirectoryIdInChild(directoryId, questionId) {
      this.directoryId = directoryId;
      this.questionId = questionId;
    }

  }
};