import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  data() {
    return {
      isusernav: false,
      isnavopne: false,
      activeNames: ['1', '2', '3'],
      // 默认二级导航展开
      shopUrl: ''
    };
  },

  components: {},
  computed: { ...mapState('moblie', {
      user_info: 'user_info',
      navText: 'navText',
      navshopitem: 'navshopitem',
      shopping_cart_num: 'shopping_cart_num'
    }),
    ...mapGetters('moblie', {
      navshopitem: 'getNavShopItem'
    }),
    ...mapState({
      user: 'user',
      userType: 'userType'
    }),

    islogin() {
      let isLogin = false;

      if (this.userType === 'user' && !_.isEmpty(this.user)) {
        isLogin = true;
      }

      return isLogin;
    }

  },

  mounted() {
    this.getCartNumber();
    const queryString = new URLSearchParams(window.location.search);
    const shopUrl = queryString.get('shopUrl');

    if (!shopUrl || shopUrl == null) {
      this.shopUrl = localStorage.getItem('shopUrl');
      return;
    }

    this.shopUrl = shopUrl;
  },

  watch: {
    isnavopne: {
      handler(nv, ov) {
        this.$store.commit('isnavopne', nv);
      }

    }
  },
  methods: { ...mapActions('moblie', ['getCartNumber', 'logOut']),

    openDown() {
      this.isusernav = !this.isusernav;
    },

    navTextfun(navText) {
      this.navText = navText; // console.log(this.navText)
    },

    toggleLanguages(local) {},

    clickLogin() {
      const href = this.navText.login + '?bkurl=' + window.location.href;
      window.location.href = href;
    },

    clickToMy() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/my.html';
      }
    },

    clickToMyOrder() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/my-order.html';
      }
    },

    async clickLogout() {
      this.$store.commit('changeUser', '');
      await this.logOut();
    },

    clickToCart() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/cart.html';
      }
    }

  }
};