import { _ } from 'core-js'
import { createStore } from 'vuex'
import config from '/config.js'
import moblie from './module/moblie'

var deal = function (catalog, nav, questionRank, editing) {
  nav = nav || []
  questionRank = questionRank || []
  for (const _catalogId in catalog) {
    // 问题类型导航
    const _catalog = catalog[_catalogId]
    nav.push({
      id: _catalog.id,
      title: _catalog.title
    })
    const _nav = _.cloneDeep(nav)
    _catalog.nav = _nav
    // 初始化编辑
    editing = false
    _catalog.editing = false
    if (!_.isEmpty(_catalog.child)) {
      var ret = deal(_catalog.child, nav, questionRank, editing)
      editing = ret.editing
      _catalog.child = ret.catalog
      questionRank = ret.questionRank
    }
    // 问题导航
    if (_catalog.question) {
      for (const _questionId in _catalog.question) {
        const _question = _catalog.question[_questionId]
        _question.nav = _nav
        _question.editing = !_question.valid
        if (_question.editing) {
          editing = true
        }
        questionRank.push(_question)
      }
    }
    // 是否正在编辑
    _catalog.editing = editing
    // 二级目录问题排行
    if (_catalog.nav.length == 2) {
      questionRank = questionRank.sort((a, b) => {
        const dateA = new Date(a.change_time).getTime()
        const dateB = new Date(b.change_time).getTime()
        return dateA < dateB ? 1 : -1
      })
      _catalog.questionSort = _.cloneDeep(questionRank)
      questionRank = []
    }
    nav.splice(nav.length - 1, 1)
  }
  return { catalog, questionRank, editing }
}
const key = window.location.pathname + '/localState'
var localState = localStorage.getItem(key) || '{}'
localState = window.opener == null ? JSON.parse(localState) : {}
export default createStore({
  state: Object.assign({
    axios: {},
    isPC: true,
    isScorePanelShow: undefined,
    href: '',
    wsid: '',
    timestamp: 0,
    user: {},
    isnavopne: '',
    userType: '', // ['admin','user']
    base: '', // ['KnowledgeBase','ChatBase']
    component: 'Home',
    person: [],
    questionType: [],
    questionTypePerson: [],
    question: [],
    questionStep: [],
    answerStep: [],
    item: [],
    questionItem: [],
    questionRecord: [],
    chatRecord: [],
    questionRecordItem: [],
    questionRecordScore: [],
    accessQuestion: [],
    filterQuestionRecord: [],
    nav: [],
    score: [],
    scoreOption: [],
    questionId: 0,
    itemFilter: [],
    questionRecordId: 0,
    questionRecordUser: '',
    questionTemp: {}, // 用于临时存储用户选择问题
    stepTemp: [], // 用于临时存储用户选择问题的步骤
    stepType: ['questionAnswer', 'login', 'goToChat'], // orderInfo
    order: [],
    scrollPosition: 0
  }, localState),
  getters: {
    questionTypeById: (state) => {
      var questionType = _.cloneDeep(state.questionType)
      var question = _.cloneDeep(state.question)
      var questionTypePerson = _.cloneDeep(state.questionTypePerson)
      var questionItem = _.cloneDeep(state.questionItem)
      var itemFilter = _.cloneDeep(state.itemFilter)
      var accessQuestion = _.cloneDeep(state.accessQuestion)
      var userType = state.userType
      var typeObj = {}
      var type = {}
      var accessQuestionByQuestionId = {}
      var questionItemByQuestionId = {}
      var questionTypePersonByQuestionTypeId = {}
      if (_.isEmpty(questionType)) {
        return typeObj
      }
      // 负责人
      for (const _questionTypePerson of questionTypePerson) {
        questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id] = questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id] || []
        questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id].push(_questionTypePerson.person_id)
      }
      // 问题类型对象
      for (const _type of questionType) {
        typeObj[_type.id] = _type
        // 负责人
        _type.person = questionTypePersonByQuestionTypeId[_type.id]
      }
      // 关联项目
      for (const _questionItem of questionItem) {
        questionItemByQuestionId[_questionItem.question_id] = questionItemByQuestionId[_questionItem.question_id] || []
        questionItemByQuestionId[_questionItem.question_id].push(_questionItem.item_id)
      }
      // 访问统计
      for (const _accessQuestion of accessQuestion) {
        accessQuestionByQuestionId[_accessQuestion.question_id] = accessQuestionByQuestionId[_accessQuestion.question_id] || []
        accessQuestionByQuestionId[_accessQuestion.question_id].push(_accessQuestion.access_user_id)
      }
      // 问题对象
      for (const _question of question) {
        _question.accessQuestion = accessQuestionByQuestionId[_question.id] || []
        _question.item = questionItemByQuestionId[_question.id]// 关联项目
        // 筛选项目关联的问题
        if (!typeObj.hasOwnProperty(_question.question_type_id)) {
          continue
        }
        typeObj[_question.question_type_id].question = typeObj[_question.question_type_id].question || {}
        const _in = _.intersection(_question.item, itemFilter)
        if (userType == 'admin') {
          if (_.isEmpty(itemFilter) || _question.item && _question.item.indexOf(0) >= 0 || _in.length > 0) {
            typeObj[_question.question_type_id].question[_question.id] = _question
          }
        } else if (userType == 'user') {
          if ((_.isEmpty(itemFilter) || _question.item && _question.item.indexOf(0) >= 0 || _in.length > 0) && _question.valid == 1) {
            typeObj[_question.question_type_id].question[_question.id] = _question
          }
        }
      }
      // 将子问题类型附加到父问题类型上
      for (const _id in typeObj) {
        const _typeObj = typeObj[_id]
        if (_typeObj.parent_id == 0) {
          continue
        }
        typeObj[_typeObj.parent_id].child = typeObj[_typeObj.parent_id].child || {}
        typeObj[_typeObj.parent_id].child[_id] = _typeObj
      }
      // 删除子问题类型
      for (const _id in typeObj) {
        const _typeObj = typeObj[_id]
        if (_typeObj.parent_id != 0) {
          continue
        }
        type[_id] = _typeObj
      }
      // return type
      return deal(type).catalog
    },
    questionTypeArrayByParentId: (state) => {
      var questionType = _.cloneDeep(state.questionType)
      var questionTypeArrayByParentId = {};
      for (const _type of questionType) {
        questionTypeArrayByParentId[_type.parent_id] = questionTypeArrayByParentId[_type.parent_id] || [];
        questionTypeArrayByParentId[_type.parent_id].push(_type);
      }
      return questionTypeArrayByParentId;
    },
    personById: (state) => {
      var person = state.person
      var personObj = {}
      for (const _person of person) {
        personObj[_person.id] = _person
      }
      return personObj
    },
    getCatalog: (state, getters) => {
      var questionTypeById = getters.questionTypeById
      return questionTypeById
    },
    getItemName: (state) => (id) => {
      const _item = state.item.find((item) => {
        return item.id == id
      })
      return _item && _item.name || ''
    },
    getItemById: (state) => (id) => {
      const _item = state.item.find((item) => {
        return item.id == id
      })
      return _item
    },
    getPersonName: (state) => (id) => {
      const _person = state.person.find((person) => {
        return person.id == id
      })
      return _person && _person.name || ''
    },
    getPersonBackgroundColor: (state) => (id) => {
      const _person = state.person.find((person) => {
        return person.id == id
      })
      return _person && _person.background_color || ''
    },
    getQuestionTypeById: (state) => {
      var questionType = _.cloneDeep(state.questionType)
      var question = _.cloneDeep(state.question)
      var questionTypePerson = _.cloneDeep(state.questionTypePerson)
      var questionItem = _.cloneDeep(state.questionItem)
      var itemFilter = _.cloneDeep(state.itemFilter)
      var userType = state.userType
      var typeObj = {}
      var questionItemByQuestionId = {}
      var questionTypePersonByQuestionTypeId = {}
      if (_.isEmpty(questionType)) {
        return typeObj
      }
      // 负责人
      for (const _questionTypePerson of questionTypePerson) {
        questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id] = questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id] || []
        questionTypePersonByQuestionTypeId[_questionTypePerson.question_type_id].push(_questionTypePerson.person_id)
      }
      // 问题类型对象
      for (const _type of questionType) {
        typeObj[_type.id] = _type
        // 负责人
        _type.person = questionTypePersonByQuestionTypeId[_type.id]
      }
      // 关联项目
      for (const _questionItem of questionItem) {
        questionItemByQuestionId[_questionItem.question_id] = questionItemByQuestionId[_questionItem.question_id] || []
        questionItemByQuestionId[_questionItem.question_id].push(_questionItem.item_id)
      }
      // 问题对象
      for (const _question of question) {
        _question.item = questionItemByQuestionId[_question.id]// 关联项目
        if (!typeObj.hasOwnProperty(_question.question_type_id)) {
          continue
        }
        // 筛选项目关联的问题
        typeObj[_question.question_type_id].question = typeObj[_question.question_type_id].question || {}
        const _in = _.intersection(_question.item, itemFilter)
        if (userType == 'admin') {
          if (_.isEmpty(itemFilter) || _in.length > 0) {
            typeObj[_question.question_type_id].question[_question.id] = _question
          }
        } else if (userType == 'user') {
          if ((_.isEmpty(itemFilter) || _in.length > 0) && _question.valid == 1) {
            typeObj[_question.question_type_id].question[_question.id] = _question
          }
        }
      }
      // 将子问题类型附加到父问题类型上
      for (const _id in typeObj) {
        const _typeObj = typeObj[_id]
        if (_typeObj.parent_id == 0) {
          continue
        }
        typeObj[_typeObj.parent_id].child = typeObj[_typeObj.parent_id].child || {}
        typeObj[_typeObj.parent_id].child[_id] = _typeObj
      }
      var type = {}
      for (const _id in typeObj) {
        const _typeObj = typeObj[_id]
        if (_typeObj.parent_id != 0) {
          continue
        }
        type[_id] = _typeObj
      }
      deal(type)
      return typeObj
    },
    getQuestionById: (state) => {
      var question = _.cloneDeep(state.question)
      var questionItem = _.cloneDeep(state.questionItem)
      var questionObj = {}
      // 关联项目
      var questionItemByQuestionId = {}
      for (const _questionItem of questionItem) {
        questionItemByQuestionId[_questionItem.question_id] = questionItemByQuestionId[_questionItem.question_id] || []
        questionItemByQuestionId[_questionItem.question_id].push(_questionItem.item_id)
      }
      for (const _question of question) {
        _question.item = questionItemByQuestionId[_question.id]
        questionObj[_question.id] = _question
      }
      return questionObj
    },
    questionStepById: (state) => {
      var questionStep = _.cloneDeep(state.questionStep)
      var answerStep = _.cloneDeep(state.answerStep)
      var questionStepObj = {}
      if (_.isEmpty(questionStep)) {
        return undefined
      }
      // 问题步骤对象
      for (const _step of questionStep) {
        _step.answerStep = []
        questionStepObj[_step.id] = _step
      }
      // 答案步骤追加到问题步骤上
      for (const _step of answerStep) {
        _step.selected = false
        if (_.isEmpty(questionStepObj[_step.question_step_id])) {
          continue
        }
        questionStepObj[_step.question_step_id].answerStep[_step.index] = _step
      }
      return questionStepObj
    },
    getQuestion: (state, getters) => {
      var questionId = state.questionId
      var question = _.cloneDeep(state.question)
      var questionStepById = getters.questionStepById
      var questionItem = _.cloneDeep(state.questionItem)
      let _question = {}
      // 查找问题
      for (const _que of question) {
        if (_que.id == questionId) {
          _question = _que
        }
      }
      if (_.isEmpty(_question)) {
        return undefined
      }
      // 关联项目
      var questionItemByQuestionId = {}
      for (const _questionItem of questionItem) {
        questionItemByQuestionId[_questionItem.question_id] = questionItemByQuestionId[_questionItem.question_id] || []
        questionItemByQuestionId[_questionItem.question_id].push(_questionItem.item_id)
      }
      _question.item = questionItemByQuestionId[_question.id]
      // 步骤问题追加到问题上
      _question.questionStep = _question.questionStep || []
      for (const _id in questionStepById) {
        const _step = questionStepById[_id]
        if (_step.question_id == _question.id) {
          _question.questionStep[_step.index] = _step
        }
      }
      return _question
    },
    getChatRecord: (state, getters) => {
      var chatRecord = state.chatRecord
      var personById = getters.personById
      for (const _chat of chatRecord) {
        _chat.person = personById[_chat.person_id]
      }
      return chatRecord
    },
    getQuestionRecordById: (state, getters) => {
      var questionRecord = _.cloneDeep(state.questionRecord)
      var chatRecord = _.cloneDeep(getters.getChatRecord)
      var questionRecordItem = _.cloneDeep(state.questionRecordItem)
      var questionRecordScore = _.cloneDeep(state.questionRecordScore)
      var scoreById = _.cloneDeep(getters.getScoreById)
      var scoreOptionById = _.cloneDeep(getters.getScoreOptionById)
      var item = _.cloneDeep(state.item)
      var itemObj = {}
      var obj = {}
      // 问题记录
      for (const _record of questionRecord) {
        _record.item = {}
        _record.chat = {}
        _record.user = JSON.parse(_record.user || '{}')
        _record.score = []
        obj[_record.id] = _record
      }
      // 问题相关项目
      for (const _item of item) {
        itemObj[_item.id] = _item
      }
      for (const _questionRecordItem of questionRecordItem) {
        if (obj.hasOwnProperty(_questionRecordItem.question_record_id)) {
          obj[_questionRecordItem.question_record_id].item[_questionRecordItem.item_id] = itemObj[_questionRecordItem.item_id]
        }
      }
      // 问题记录相关评分
      for (const _questionRecordScore of questionRecordScore) {
        if (_.isEmpty(obj[_questionRecordScore.question_record_id])) {
          continue
        }
        const _score = scoreById[_questionRecordScore.score_id]
        if (_score === undefined) {
          continue
        }
        _score.scoreOption = []
        const scoreOptionIdArray = JSON.parse(_questionRecordScore.score_option_id_array || '[]')
        for (const scoreOptionId of scoreOptionIdArray) {
          _score.scoreOption.push(scoreOptionById[scoreOptionId])
        }
        _score.suggest = _questionRecordScore.suggest
        _score.time = _questionRecordScore.time
        _score.scoreOptionIdArray = JSON.parse(_questionRecordScore.score_option_id_array || '[]')

        obj[_questionRecordScore.question_record_id].score.push(_score)
      }
      // 聊天记录
      for (const _chat of chatRecord) {
        _chat.user = JSON.parse(_chat.user || '{}')
        if (obj.hasOwnProperty(_chat.question_record_id)) {
          obj[_chat.question_record_id].chat[_chat.id] = _chat
        }
      }
      return obj
    },
    getQuestionRecordUnsolvedCount: (state) => {
      var questionRecord = _.cloneDeep(state.questionRecord)
      var count = 0
      for (const _questionRecord of questionRecord) {
        if (!_questionRecord.is_solved) {
          count++
        }
      }
      return count
    },
    getQuestionRecordUnreadCount: (state) => {
      var questionRecord = _.cloneDeep(state.questionRecord)
      var userType = state.userType
      var count = 0
      for (const _questionRecord of questionRecord) {
        if (_questionRecord[userType + '_unread']) {
          count++
        }
      }
      return count
    },
    getQuestionRecordCount: (state) => {
      var questionRecord = _.cloneDeep(state.questionRecord)
      var count = questionRecord.length
      return count
    },
    getQuestionTypeNavShow: () => (nav) => {
      var titleArray = []
      for (const _nav of nav) {
        titleArray.push(_nav.title)
      }
      return titleArray.join('→')
    },
    getQuestionNavShow: () => (nav, title) => {
      var titleArray = []
      for (const _nav of nav) {
        titleArray.push(_nav.title)
      }
      titleArray.push(title)
      return titleArray.join('→')
    },
    getItemByName: (state) => {
      var item = _.cloneDeep(state.item)
      var itemByName = {}
      for (const _item of item) {
        itemByName[_item.name] = _item
      }
      return itemByName
    },
    getQuestionTypeByParentId: (state) => (pid) => {
      var questionType = _.cloneDeep(state.questionType)
      var typeArray = []
      for (const _type of questionType) {
        if (_type.parent_id == pid) {
          typeArray.push(_type)
        }
      }
      return typeArray
    },
    getFilterQuestionRecordByUser: (state) => {
      var user = _.cloneDeep(state.user)
      var userType = _.cloneDeep(state.userType)
      var filterQuestionRecord = _.cloneDeep(state.filterQuestionRecord)
      var filterQuestionRecordByUser = {}
      for (var filter of filterQuestionRecord) {
        if (userType == 'admin' && user.id == filter.person_id) {
          filterQuestionRecordByUser = filter
        } else if (userType == 'user' && JSON.stringify(user) == filter.user) {
          filterQuestionRecordByUser = filter
        }
      }
      return filterQuestionRecordByUser
    },
    getValidItem: (state) => {
      const validItem = []
      for (const _item of state.item) {
        if (!_item.delete) {
          validItem.push(_item)
        }
      }
      return validItem
    },
    getScore: (state) => {
      const score = _.cloneDeep(state.score)
      const scoreOption = _.cloneDeep(state.scoreOption)
      const scoreById = {}
      for (const _score of score) {
        scoreById[_score.id] = _score
      }
      for (const _option of scoreOption) {
        if (_option.delete) {
          continue
        }
        scoreById[_option.score_id].scoreOption = scoreById[_option.score_id].scoreOption || []
        scoreById[_option.score_id].scoreOption.push(_option)
      }
      return score
    },
    getScoreById: (state) => {
      const score = _.cloneDeep(state.score)
      const scoreById = {}
      for (const _score of score) {
        scoreById[_score.id] = _score
      }
      return scoreById
    },
    getScoreOptionById: (state) => {
      const scoreOption = _.cloneDeep(state.scoreOption)
      const scoreOptionById = {}
      for (const _scoreOption of scoreOption) {
        if (_scoreOption.delete) {
          continue
        }
        scoreOptionById[_scoreOption.id] = _scoreOption
      }
      return scoreOptionById
    }
  },
  mutations: {
    setScrollPosition(state, payload) {
      state.scrollPosition = payload;
    },
    changeAxios: (state, payload) => {
      state.axios = payload
    },
    changeIsPC: (state, payload) => {
      state.isPC = payload
    },
    changeIsScorePanelShow: (state, payload) => {
      state.isScorePanelShow = payload
    },
    changeUrl: (state, payload) => {
      state.url = payload
    },
    changeWsid: (state, payload) => {
      state.wsid = payload
    },
    changeTimestamp: (state, payload) => {
      state.timestamp = payload
    },
    changeUser: (state, payload) => {
      state.user = payload
    },
    isnavopne: (state, payload) => {
      state.isnavopne = payload
    },
    changeUserType: (state, payload) => {
      state.userType = payload
    },
    changeBase: (state, payload) => {
      state.base = payload
    },
    changeComponent: (state, payload) => {
      state.component = payload
    },
    changeNav: (state, payload) => {
      state.nav = payload
    },
    changeQuestionId: (state, payload) => {
      state.questionId = payload
    },
    updateTable: (state, payload) => {
      var table = payload.table
      var content = payload.content
      state[table] = content
    },
    updateTablePart: (state, payload) => {
      var table = payload.table
      var addArray = payload.addArray
      var deleteArray = payload.deleteArray
      var changeArray = payload.changeArray
      // 新增数据
      if (addArray && addArray.length > 0) {
        state[table] = _.concat(state[table], addArray)
      }
      // 删除数据
      if (deleteArray && deleteArray.length > 0) {
        _.pullAllBy(state[table], deleteArray, 'id')
      }
      // 修改数据
      if (changeArray && changeArray.length > 0) {
        for (const _change of changeArray) {
          for (const _data of state[table]) {
            if (_change.id == _data.id) {
              Object.assign(_data, _change)
            }
          }
        }
      }
      // 按id排序（待定）
    },
    changeItemFilter: (state, payload) => {
      state.itemFilter = payload
    },
    changeQuestionRecordUser: (state, payload) => {
      state.questionRecordUser = payload
    },
    changeQuestionRecordId: (state, payload) => {
      state.questionRecordId = payload
    },
    changeQuestionTemp: (state, payload) => {
      state.questionTemp = payload
    },
    changeStepTemp: (state, payload) => {
      state.stepTemp = payload
    },
    changeOrder: (state, payload) => {
      state.order = payload
    }
  },
  actions: {
    updateRemoteTablePart: ({ state, dispatch }, payload) => {
      var userType = state.userType
      state.axios.post('/api/' + userType + '/Base/updateRemoteTablePart', payload).then().catch((err) => {
        setTimeout(() => {
          dispatch('updateRemoteTablePart', payload)
        }, 1000)
      })
    },
    accessStatistics: ({ state, dispatch }) => {
      if (state.questionId) {
        var userType = state.userType
        state.axios.post('/api/' + userType + '/Base/accessStatistics', {
          question_id: state.questionId,
          base: state.base,
          component: state.component
        }).then().catch((err) => {
          setTimeout(() => {
            dispatch('accessStatistics')
          }, 1000)
        })
      }
    },
    openChat: ({ state, dispatch }, payload) => {
      var userType = state.userType
      if (userType == 'admin') {
        window.open('/admin/chat');
      } else if (userType == 'user') {
        if (window.parent != window) {
          const winOpen = window.open(config.chatUrl(state.isPC), '_blank', '', true);
          if (!winOpen) {
            window.location.href = config.chatUrl(state.isPC);
          }
          // window.open(config.chatUrl,'_top');
        } else {
          const winOpen = window.open(config.chatUrl(state.isPC), '_blank');
          if (!winOpen) {
            window.location.href = config.chatUrl(state.isPC);
          }
        }
      }
    }
  },
  modules: {
    moblie
  }
})
